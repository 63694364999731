import { useState, useCallback } from "react";

export const useLocalStorage =
    typeof document !== "undefined"
        ? <T>(key: string, initialValue: T) => {
              const [storedValue, setStoredValue] = useState<T>(() => {
                  try {
                      const item = window.localStorage.getItem(key);
                      return item ? JSON.parse(item) : initialValue;
                  } catch (error) {
                      console.error(error);
                      return initialValue;
                  }
              });

              const setValue = (key: string, value: T | ((value: T) => T)) => {
                  try {
                      const valueToStore = value instanceof Function ? value(storedValue) : value;
                      window.localStorage.setItem(key, JSON.stringify(valueToStore));
                      setStoredValue(valueToStore);
                  } catch (error) {
                      console.error(error);
                  }
              };

              return [storedValue, setValue] as const;
          }
        : <T>(_key: string, initialValue: T) => {
              return useState<T>(initialValue);
          };

export const useDeleteFromLocalStorage = () => {
    const deleteValue = useCallback((key: string) => {
        try {
            if (typeof window !== "undefined") {
                window.localStorage.removeItem(key);
            }
        } catch (error) {
            // do nothing
        }
    }, []);

    return [deleteValue] as const;
};

export default useLocalStorage;

import isEqual from "lodash/isEqual";
import pick from "lodash/pick";
import { useSearchParams } from "next/navigation";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

import useLocalStorage from "./useLocalStorage";

const keys = ["bookingExternalReference", "exitLink", "returnLink", "exitLabel"] as const;

type PersistedQueryParamsContextValue = {
    bookingExternalReference?: string;
    exitLink?: string;
    exitLabel?: string;
    returnLink?: string;
};

export const PersistedQueryParamsContext = createContext<PersistedQueryParamsContextValue>({});

export function usePersistedQueryParamsContextValue(): PersistedQueryParamsContextValue {
    const searchParams = useSearchParams();
    const key = "persistedqueryparams";
    const [storedValue, setStoredValue] = useLocalStorage<PersistedQueryParamsContextValue | null>(key, null);
    const [initialState] = useState<PersistedQueryParamsContextValue>(storedValue ?? {});

    const contextValue = useMemo(() => {
        const queryValues: Record<string, string | null> = {};
        for (const key of keys) {
            if (searchParams.has(key)) {
                queryValues[key] = searchParams.get(key);
            }
        }
        const storedValues = pick(initialState, keys);
        const contextValue = { ...storedValues, ...queryValues };

        return contextValue as PersistedQueryParamsContextValue;
    }, [searchParams, initialState]);

    useEffect(() => {
        if (!isEqual(contextValue, storedValue)) {
            setStoredValue(key, contextValue);
        }
    }, [storedValue, setStoredValue, contextValue]);

    return contextValue;
}

export function usePersistedQueryParams(): PersistedQueryParamsContextValue {
    return useContext(PersistedQueryParamsContext);
}

export function useReturnLink(): string | undefined {
    const { returnLink } = usePersistedQueryParams();

    return returnLink;
}

export function useExitLink(): string | undefined {
    const { exitLink } = usePersistedQueryParams();

    return exitLink;
}

export function useExitLabel(): string | undefined {
    const { exitLabel } = usePersistedQueryParams();

    return exitLabel;
}

export function useBookingExternalReference() {
    const { bookingExternalReference } = usePersistedQueryParams();

    return bookingExternalReference;
}

export default usePersistedQueryParams;
